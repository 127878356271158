import {invoke, ApiMethod} from '../invoker';
import {appendSlashIfNeeded} from '../endpointUtil';

export const login = (
  email: string,
  password: string,
  deviceId: string,
): /* place holder request params, body, headers, ect. */
Promise<any> => {
  const endpointDomain = 'http://localhost:1337/https://0pecoadihl.execute-api.us-west-2.amazonaws.com';
  const resourcePath = `${appendSlashIfNeeded('test/login')}`;
  const method = ApiMethod.POST;
  const body = {
    email,
    password,
    deviceId,
  };
  const headers = {
    'Content-Type': 'application/json',
  };

  console.log(
    `invoke login - endpoint: ${endpointDomain}, 
      resourcePath: ${resourcePath}, method: ${method}, headers: ${JSON.stringify(
      headers,
    )}, body: ${JSON.stringify(body)}`,
  );

  return invoke(
    endpointDomain,
    resourcePath,
    method,
    headers,
    JSON.stringify(body),
  );
};

export const createAccount = (
): /* place holder request params, body, headers, ect. */
Promise<any> => {
  const endpointDomain = '';
  const resourcePath = `${appendSlashIfNeeded('test/createAccount')}`;
  const method = ApiMethod.POST;
  const body = {
  };
  const headers = {
    'Content-Type': 'application/json',
  };

  console.log(
    `invoke createAccount - endpoint: ${endpointDomain}, 
      resourcePath: ${resourcePath}, method: ${method}, headers: ${JSON.stringify(
      headers,
    )}, body: ${JSON.stringify(body)}`,
  );

  return invoke(
    endpointDomain,
    resourcePath,
    method,
    headers,
    JSON.stringify(body),
  );
};

// export const login = (
//     ): /* place holder request params, body, headers, ect. */
//     Promise<any> => {
//       const endpointDomain = '';
//       const resourcePath = `${appendSlashIfNeeded('test/login')}`;
//       const method = ApiMethod.POST;
//       const body = {
//       };
//       const headers = {
//         'Content-Type': 'application/json',
//       };
    
//       console.log(
//         `invoke login - endpoint: ${endpointDomain}, 
//           resourcePath: ${resourcePath}, method: ${method}, headers: ${JSON.stringify(
//           headers,
//         )}, body: ${JSON.stringify(body)}`,
//       );
    
//       return invoke(
//         endpointDomain,
//         resourcePath,
//         method,
//         headers,
//         JSON.stringify(body),
//       );
//     };