import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import Context, { RegistrationType } from "../Context";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  instruction: {
    marginTop: theme.spacing(1),
  }
}));

const TechCertifications = () => {
  const classes = useStyles();

  const { state, nav, api } = useContext(Context);

  //todo: pull certs from api
  const certList = [
    { cert: "A+ Cert1", certName: "A+ Cert1" },
    { cert: "A+ Cert2", certName: "A+ Cert2" },
    { cert: "A+ Cert3", certName: "A+ Cert3" },
    { cert: "A+ Cert4", certName: "A+ Cert4" },
    { cert: "A+ Cert5", certName: "A+ Cert5" },
    { cert: "A+ Cert6", certName: "A+ Cert6" },
    { cert: "A+ Cert11", certName: "A+ Cert11" },
    { cert: "A+ Cert12", certName: "A+ Cert12" },
    { cert: "A+ Cert13", certName: "A+ Cert13" },
    { cert: "A+ Cert14", certName: "A+ Cert14" },
    { cert: "A+ Cert15", certName: "A+ Cert15" },
    { cert: "A+ Cert16", certName: "A+ Cert16" },
  ];

  const onRemoveSelectedCert = (item: any) => {
    const updatedArray = state.selectedCerts.filter(
      (cert: string) => cert !== item
    );
    state.setSelectedCerts(updatedArray);
  };

  const handleCreateAccount = () => {
    console.log("handleCreateAccount called");
    api.createOrgAccount(
      (response: any) => {
        if (response.isSuccess) {
          nav.setNextStep();
        } else {
          //error condition
        }
      },
      (error: any) => {
        console.log(`exception: ${error}`);
      }
    );
  };

  const handleBack = () => {
    nav.setBackStep();
  };

  const shouldDisable = () => {
    return true;
    // if (state.registrationType === RegistrationType.Technician) {
    //   const missingFields = state.selectedCerts.length > 0 ? false : true;
    //   console.log(`missingFields:${missingFields}`);
    //   return missingFields;
    // } else {
    //   return state.orgName.length > 0 ? false : true;
    // }

                // backgroundColor: shouldDisable() ? "lightgrey" : "#FE5723",
  };

  const handleAddSelectedCert = (event: any) => {
    if (!state.selectedCerts.includes(event.target[event.target.value].text)) {
      state.setSelectedCerts([...state.selectedCerts, event.target[event.target.value].text]);

    }
  };

  return (
    <React.Fragment>
      {state.registrationType === RegistrationType.Organization && (
        <>
          <Typography variant="h6" gutterBottom>
            If your information is complete, click Create Account to complete your registration.
          </Typography>
        </>
      )}
      {state.registrationType === RegistrationType.Technician && (
        <>
          <Typography variant="h6" gutterBottom>
            Select your certifications:
          </Typography>
          <Box>
            {state &&
              state.selectedCerts &&
              state.selectedCerts.length > 0 &&
              state.selectedCerts.map((item: string) => (
                <Chip
                  key={item}
                  label={item}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => onRemoveSelectedCert(item)}
                  onClick={() => onRemoveSelectedCert(item)}
                  style={{ margin: 5 }}
                />
              ))}
          </Box>
          <FormControl className={classes.instruction}>
            <InputLabel htmlFor="cert-name">Certifications</InputLabel>
            <Select
              native
              value={"cert"}
              onChange={(event) => handleAddSelectedCert(event)}
              inputProps={{
                name: "cert",
                id: "certName",
              }}
              style={{ width: 250 }}
            >
              <option value={""}>{""}</option>
              {state.certifications &&
                state.certifications.map((cert: any) => {
                  return <option value={cert.id}>{`${cert.issuer} - ${cert.name}`}</option>;
                })}
            </Select>
          </FormControl>
        </>
      )}
      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleCreateAccount}
          className={classes.button}
          style={{
            backgroundColor: 'lightgrey',
            color: "#FFF",
          }}
          disabled={shouldDisable()}
        >
          {"Create Account"}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default TechCertifications;
