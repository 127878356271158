import {withRouter} from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import Context from '../Context';

const HomeDetails = (props: any): JSX.Element => {
    const { nav, state, api } = useContext(Context);
    const [loadMapping, setLoadMapping] = useState(false);
    
    useEffect(() => {
        if(!loadMapping){
            api.getOrgSetupMapping(
                () => { 
                  console.log(`loaded org setup mapping successfully!`);
                  setLoadMapping(true);
                }, 
                (error: any) => {
                  console.log(`error in getOrgSetupMapping: ${JSON.stringify(error)}`);
                });
        }

    }, [loadMapping]);

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center">
                <div style={{ flexDirection: 'column', marginLeft: 'auto', padding: 2}}><Typography>Already have an account? <Button style={{color: '#FE5723', fontWeight: 'bolder'}} onClick={() => {props.history.push('/login')}}> Log In</Button></Typography></div>                      
                <Typography style={{ flexWrap: 'wrap', fontWeight: 'bold', color: '#0d456d', width: '70%', minWidth: '50%', fontSize: 45, marginLeft: 'auto', marginRight: 'auto', marginTop: '15vh' }} >Register as a certified IT Technician today and reach a network of customers in your area 24/7.</Typography>
                <Button style={{backgroundColor: '#FE5723', color: '#FFF', width: 200, marginTop: '15vh', marginBottom: '10vh'}} onClick={() => {props.history.push('/register')}}>Sign Up</Button>
        </Grid>
    );
};

export default withRouter(HomeDetails);