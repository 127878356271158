import { Typography } from "@material-ui/core";
import React from "react";

const AccountCreatedView = () => {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Your account has been created!
      </Typography>
      <Typography variant="subtitle1">
        An email is on its way to your mailbox with information regarding your
        new account and instructions to get started!
      </Typography>
    </React.Fragment>
  );
};

export default AccountCreatedView;
