import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const LoginView = (props: any): JSX.Element => {
  const classes = useStyles();

  const handleLogin = () => {};

  return (
    <Grid
      container
      direction="column"
      justify="space-evenly"
      alignItems="center"
      style={{ backgroundColor: "#0d456d" }}
    >
      <CssBaseline />
      <main className={classes.layout}>
        <div
          style={{
            height: "80vh",
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Paper
            className={classes.paper}
            style={{ minHeight: 300, height: "40vh", backgroundColor: "#FFF" }}
          >
            <Typography
              component="h1"
              variant="h4"
              align="center"
              style={{ paddingTop: 15, paddingBottom: 20 }}
            >
              Login
            </Typography>
            <React.Fragment>
              {/* <Typography variant="h6" gutterBottom>Your information</Typography> */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="email"
                    name="emailAddress"
                    label="Email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="pword"
                    name="pword"
                    label="Password"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </React.Fragment>

            <React.Fragment>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {}}
                  className={classes.button}
                  style={{ backgroundColor: "#FE5723", color: "#FFF" }}
                >
                  {"Login"}
                </Button>
              </div>
            </React.Fragment>
          </Paper>
        </div>
      </main>
      <Copyright />
      {/* <Button style={{backgroundColor: '#FE5723', color: '#FFF', width: 200, marginTop: '15vh'}} >Register</Button> */}
    </Grid>
  );
};

export default withRouter(LoginView);
