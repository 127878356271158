import axios from 'axios';
import {appendSlashIfNeeded} from './endpointUtil';

export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  LINK = 'LINK',
  UNLINK = 'UNLINK',
}

export const invoke = async (
  endpointDomain: string,
  resourcePath: string,
  apiMethod: ApiMethod,
  apiHeaders: string[][] | {[key: string]: string} | undefined,
  payloadBody: any,
): Promise<any> => {
  const url = `${endpointDomain}${appendSlashIfNeeded(resourcePath)}`;

  console.log(`url: ${url}`);
  return await axios({
    method: apiMethod,
    url,
    data: payloadBody,
    headers: apiHeaders,
  });
};
