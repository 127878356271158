import Box from '@material-ui/core/Box';
import Splash from '../assets/splash.png';
import {
    BrowserRouter as Router,
    Route
  } from "react-router-dom";

import RegistrationView from './RegistrationView';
import HomeDetails from './HomeDetails';
import React, { useContext, useEffect } from 'react';
import LoginView from './LoginView';
import Context from '../Context';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  splashImg: {
    height: '100vh',
    width: 600,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
}));



const HomeView = (): JSX.Element => {
  const classes = useStyles();

    return <div style={{ display: 'flex', flex: 10, flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Box className={classes.splashImg}>
            <img src={Splash} height= '100%' alt="Logo" /> 
        </Box>
        <Router>
            <Route exact path='/'><HomeDetails /></Route>
            <Route path='/register'><RegistrationView /></Route>
            <Route path='/login'><LoginView /></Route>
        </Router>
    </div>
};

export default HomeView;

//FE5723