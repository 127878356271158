import Box from "@material-ui/core/Box";
import Image from "material-ui-image";
import Splash from "../assets/splash.png";
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Copyright from "../components/Copyright";
import RegistrationDetailsForm from "./RegistrationDetailsForm";
import RegistrationTypeForm from "./RegistrationTypeForm";
import TechCertifications from "./TechCertifications";
import Context from "../Context";
import AccountCreatedView from "./AccountCreatedView";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 650,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  stepper: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [
  "Select your account type",
  "Enter your information",
  "Create Account",
];

function getStepContent(step: Number) {
  switch (step) {
    case 0:
      return <RegistrationTypeForm />;
    case 1:
      return <RegistrationDetailsForm />;
    case 2:
      return <TechCertifications />;
    case 3:
      return <AccountCreatedView />;
    default:
      throw new Error("Unknown step");
  }
}

const RegistrationView = (): JSX.Element => {
  const classes = useStyles();

  const { api, state } = useContext(Context);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      style={{ backgroundColor: "#0d456d", paddingTop: "10vh"}}
    >
      <main className={classes.layout}>
        <div
          style={{ backgroundColor: "#FFF", borderRadius: 4 }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            style={{ paddingTop: 15 }}
          >
            Registration
          </Typography>
          <Stepper activeStep={state.activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label} style={{ color: "#FE5723" }}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Paper
            className={classes.paper}
            style={{ minHeight: 200, height: "55vh", maxHeight: "55vh", overflowY: "scroll", marginBottom: "10vh" }}
          >
            <React.Fragment>
              <React.Fragment>
                {getStepContent(state.activeStep)}
              </React.Fragment>
            </React.Fragment>
          </Paper>
        </div>
      </main>
      <Copyright />
      {/* <Button style={{backgroundColor: '#FE5723', color: '#FFF', width: 200, marginTop: '15vh'}} >Register</Button> */}
    </Grid>
  );
};

export default withRouter(RegistrationView);

//FE5723
