import HomeView from "./views/HomeView";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { AppContextWrapper } from "./Context";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d456d",
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          fill: "#0d456d",
          "& $text": {
            fill: "#FFF",
          },
        },
        "&$completed": {
          fill: "#0d456d",
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "#0d456d",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#0d456d",
        },
      },
    },
    MuiSelect: {
      select: {
        "&$active": {
          color: "red",
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(13,69,109, 0.65)",
        // "&$active": {
        //   backgroundColor: "rgba(13,69,109, 0.85)",
        // },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContextWrapper>
        <HomeView />
      </AppContextWrapper>
    </ThemeProvider>
  );
}

export default App;
