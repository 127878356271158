import {invoke, ApiMethod} from '../invoker';
import {appendSlashIfNeeded} from '../endpointUtil';

export const getOrgSetupMapping = (
):
Promise<any> => {
  console.log(`API_GATEWAY_GET_ORG_MAPPING: ${process.env.REACT_APP_API_GATEWAY_GET_ORG_MAPPING}`);
  const endpointDomain = process.env.REACT_APP_API_GATEWAY_GET_ORG_MAPPING|| "";
  const resourcePath = `${appendSlashIfNeeded(`demo-mvp/getOrgSetupMapping`)}`;
  const method = ApiMethod.POST;
  const body = {
  };
  const headers = {
    'Content-Type': 'application/json',
  };

  console.log(
    `invoke getOrgSetupMapping - endpoint: ${endpointDomain}, 
      resourcePath: ${resourcePath}, method: ${method}, headers: ${JSON.stringify(
      headers,
    )}, body: ${JSON.stringify(body)}`,
  );

  return invoke(
    endpointDomain,
    resourcePath,
    method,
    headers,
    JSON.stringify(body),
  );
};


export const createOrgAccount = (
  orgName: string,
  industryId: number,
  orgSizeId: number,
  orgUserFullName: string,
  orgUserEmail: string,
  phoneNumber: string,
  addressLine1: string,
  city: string,
  state: string,
  zip: string,
  ):
  Promise<any> => {
    const endpointDomain = process.env.REACT_APP_API_GATEWAY_ADD_ORG_MEMBER|| "";
    const resourcePath = `${appendSlashIfNeeded(`demo-mvp/addOrgEntity`)}`;
    const method = ApiMethod.POST;
    const body = {
      orgName,
      orgSizeId,
      industryId,
      email: orgUserEmail,
      phoneNumber,
      contactName: orgUserFullName,
      street: addressLine1,
      city,
      state,
      zip
    };
    const headers = {
      'Content-Type': 'application/json',
    };
  
    console.log(
      `invoke addOrgMember - endpoint: ${endpointDomain}, 
        resourcePath: ${resourcePath}, method: ${method}, headers: ${JSON.stringify(
        headers,
      )}, body: ${JSON.stringify(body)}`,
    );
  
    return invoke(
      endpointDomain,
      resourcePath,
      method,
      headers,
      JSON.stringify(body),
    );
  };
