import { Select } from "@material-ui/core";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { formatPhoneNumber, isEmailValid } from "../common/utils";
import Context, { RegistrationType } from "../Context";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  countryField: {
    width: 250,
    border: 0,
    // height: 1.1876em,
    margin: 0,
    padding: "6px 0 9px",
    // box-sizing: content-box,
  },
  dropdownField: {
    width: "100%",
    border: 0,
    margin: "17px 0 9px",
    color: "rgba(0, 0, 0, 0.87)"
  },
}));

const RegistrationDetailsForm = () => {
  const classes = useStyles();
  const { nav, state, api } = useContext(Context);

  const handleNext = () => {
    nav.setNextStep();
  };

  const handleBack = () => {
    nav.setBackStep();
  };

  const handleSetFirstName = (name: any) => {
    //console.log("handleSetFirstName called");
    state.setFirstName(name.target.value);
  };
  const handleSetLastName = (name: any) => {
    //console.log("handleSetLastName called");
    state.setLastName(name.target.value);
  };

  const handleSetEmail = (name: any) => {
    //console.log("handleSetEmail called");
    state.setEmail(name.target.value);
  };

  const handleSetPhoneNumber = (name: any) => {
    state.setPhoneNumber(name);
  };

  

  const handleSetAddressLine1 = (name: any) => {
    state.setAddressLine1(name.target.value);
  };

  const handleSetAddressLine2 = (name: any) => {
    state.setAddressLine2(name.target.value);
  };

  const handleSetCity = (name: any) => {
    //console.log("handleSetCity called");
    state.setCity(name.target.value);
  };

  const handleSetProvince = (name: any) => {
    //console.log("handleSetProvince called");
    state.setProvince(name.target.value);
  };

  const handleSetZipCode = (name: any) => {
    //console.log("handleSetZipCode called");
    state.setZipCode(name.target.value);
  };

  const handleSetCountry = (name: any) => {
    //console.log("handleSetCountry called");
    state.setCountry(name.target.value);
  };

  const handleSetOrg = (name: any) => {
    state.setOrgName(name.target.value);
  };

  const handleSetRole = (name: any) => {
    state.setRole(name.target.value);
  };

  const handleSetIndustry = (event: any) =>  {
    state.setIndustryId(event.target.value);
  };

  const handleSetOrgSizeId = (event: any) => {
    state.setOrgSizeId(event.target.value);
  };

  const areFieldsMissing = () => {
    //return false;
    const missingFields =
      state &&
      state.firstName.length > 0 &&
      state.lastName.length > 0 &&
      isEmailValid(state.email) &&
      state.addressLine1.length > 6 &&
      state.city.length > 0 &&
      state.province.length > 0 &&
      state.zipCode.length >= 5 //&&
        ? //state.country.length >= 2
          false
        : true;

    const orgFieldsMissing =
      state.registrationType == RegistrationType.Organization
        ? state.orgName.length <= 0
        : false;
    console.log(`missingFields:${missingFields}`);
    return missingFields || orgFieldsMissing;
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {state.registrationType == RegistrationType.Organization && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="org"
                name="org"
                label="Organization"
                fullWidth
                value={state.orgName || ""}
                onChange={(text) => handleSetOrg(text)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                native
                value={state.role || ""}
                onChange={(text) => { handleSetRole(text) }}
                inputProps={{
                  name: "role",
                  id: "role",
                }}
                className={classes.dropdownField}
              >
                <option value={"NotSelected"}>{"Your Role:"}</option>
                <option value={"Admin"}>{"Admin"}</option>
              </Select>
            </Grid>
  
            <Grid item xs={12} sm={6}>
            <Select
                native
                value={state.industryId || ''}
                onChange={(text) => { handleSetIndustry(text)}}
                inputProps={{
                  name: "industry",
                  id: "industry",
                }}
                className={classes.dropdownField}
              >
                <option value={"NotSelected"}>{"Your Industry:"}</option>
                {state.industries && state.industries.map((ind: any, i: number) => {
                  return (<option key={i} value={ind.id}>{ind.name}</option>)
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Select
                native
                value={state.orgSizeId || 0}
                onChange={(text) => { handleSetOrgSizeId(text)}}
                inputProps={{
                  name: "range",
                  id: "range",
                }}
                className={classes.dropdownField}
              >
                <option value={"NotSelected"}>{"# of Employees:"}</option>
                {state.orgSizes && state.orgSizes.map((ind: any, i: number) => {
                  return (<option key={i} value={ind.id}>{ind.size}</option>)
                })}
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            value={state.firstName || ""}
            onChange={(text) => handleSetFirstName(text)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            value={state.lastName || ""}
            onChange={(text) => handleSetLastName(text)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="emailAddress"
            label="Email"
            fullWidth
            value={state.email || ""}
            onChange={(text) => handleSetEmail(text)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            value={formatPhoneNumber(state.phoneNumber)}
            onChange={(event) => {
              handleSetPhoneNumber(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            value={state.addressLine1 || ""}
            onChange={(text) => handleSetAddressLine1(text)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            value={state.addressLine2 || ""}
            onChange={(text) => handleSetAddressLine2(text)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            value={state.city || ""}
            onChange={(text) => handleSetCity(text)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            value={state.province || ""}
            onChange={(text) => handleSetProvince(text)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            value={state.zipCode || ""}
            onChange={(text) => handleSetZipCode(text)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            native
            value={state.country || ""}
            onChange={(text) => handleSetCountry(text)}
            inputProps={{
              name: "countryName",
              id: "countryCode",
            }}
            className={classes.dropdownField}
          >
            <option value={"US"}>{"US"}</option>
          </Select>
          {/* <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            value={state.country || ""}
            onChange={(text) => handleSetCountry(text)}
          /> */}
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button onClick={handleBack} className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          className={classes.button}
          style={{
            backgroundColor: areFieldsMissing() ? "lightgrey" : "#FE5723",
            color: "#FFF",
          }}
          disabled={areFieldsMissing()}
        >
          {"Next"}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default RegistrationDetailsForm;
