import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { login } from "../src/api/services/authService";
import { createOrgAccount } from "../src/api/services/orgService";
import { toBool } from "../src/common/utils";
import { getOrgSetupMapping } from "./api/services/orgService";

type ApiInvoker = {
  createOrgAccount: Function;
  login: Function;
  getTechCertifications: Function;
  getOrgSetupMapping: Function;
};

const emptyFunc = () => {};

export const defaultInvoker: ApiInvoker = {
  createOrgAccount: emptyFunc,
  login: emptyFunc,
  getTechCertifications: emptyFunc,
  getOrgSetupMapping: emptyFunc,
};

export const defaultState = {};

export const defaultAppConfig = {};

export const defaultNav = {
  setNextStep: emptyFunc,
  setBackStep: emptyFunc,
};

export enum RegistrationType {
  Technician = "technician",
  Organization = "organization",
}

type ContextProps = {
  api: ApiInvoker;
  state: any;
  nav: any;
};

const AppContext = createContext<ContextProps>({
  state: defaultState,
  api: defaultInvoker,
  nav: defaultNav,
});

type ContextWrapperProps = {
  children: React.ReactNode;
};

export const AppContextWrapper: React.FC<ContextWrapperProps> = (
  props
): JSX.Element => {
  const { children } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [registrationType, setRegistrationType] = useState("");
  const [selectedCerts, setSelectedCerts] = useState<string[]>([]);
  const [orgName, setOrgName] = useState("");
  const [role, setRole] = useState("");
  const [industryId, setIndustryId] = useState<number>(0);
  const [orgSizeId, setOrgSizeId] = useState<number>(0);
  const [industries, setIndustries] = useState<[]>([]);
  const [orgSizes, setOrgSizes] = useState<[]>([]);
  const [certifications, setCertifications] = useState<[]>([]);
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  let state = {
    activeStep,
    registrationType,
    setRegistrationType,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    province,
    setProvince,
    zipCode,
    setZipCode,
    country,
    setCountry,
    selectedCerts,
    setSelectedCerts,
    orgName,
    setOrgName,
    role,
    setRole,
    industryId,
    setIndustryId,
    industries,
    orgSizeId,
    setOrgSizeId,
    orgSizes,
    certifications,
    phoneNumber,
    setPhoneNumber
  };

  let nav = {
    setNextStep: handleNextStep,
    setBackStep: handleBackStep,
  };

  const handleLogin = (
    successCallback: Function | undefined,
    failureCallback: Function | undefined
  ) => {
    login("email", "password", "deviceId")
      .then((response) => {
        console.log(
          `### AppContext.login response: ${JSON.stringify(response)}`
        );
        if (successCallback) {
          successCallback(response.data);
        }
      })
      .catch((exception) => {
        if (failureCallback) {
          failureCallback(exception);
        }
      });
  };

  const handleCreateOrgAccount = (
    successCallback: Function | undefined,
    failureCallback: Function | undefined
  ) => {
    console.log(`*********  PHONE NUMBER: ${state.phoneNumber}`);
    createOrgAccount(state.orgName, state.industryId, state.orgSizeId, `${state.firstName} ${state.lastName}`, state.email, state.phoneNumber, state.addressLine1, state.city, state.province, state.zipCode)
      .then(response => {
        console.log(
          `***** response from createOrgAccount: ${JSON.stringify(
            response,
          )} *****`,
        );
        if (successCallback) {
          successCallback(response.data);
        }
      })
      .catch(exception => {
        if (failureCallback) {
          failureCallback(exception);
        }
      });
  };

  const handleGetOrgMapping = (
    successCallback?: Function | undefined,
    failureCallback?: Function | undefined,
  ) => {
    getOrgSetupMapping()
      .then(response => {
        console.log(
          `***** response from getOrgSetupMapping: ${JSON.stringify(
            response,
          )} *****`,
        );
        setIndustries(response.data.industries);
        setOrgSizes(response.data.orgSizes);
        setCertifications(response.data.certifications);
        if (successCallback) {
          successCallback(response.data);
        }
      })
      .catch(exception => {
        if (failureCallback) {
          failureCallback(exception);
        }
      });
  };

  const handleGetTechCertification = () => {};

  const api: ApiInvoker = {
    createOrgAccount: handleCreateOrgAccount,
    login: handleLogin,
    getTechCertifications: handleGetTechCertification,
    getOrgSetupMapping: handleGetOrgMapping,
  };

  const config = defaultAppConfig;

  return (
    <AppContext.Provider value={{ api, state, nav }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppContext;
