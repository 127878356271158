import {
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Context, { RegistrationType } from "../Context";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const RegistrationTypeForm = () => {
  const { nav, state, api } = useContext(Context);

  useEffect(() => {});

  const classes = useStyles();
  const handleNext = () => {
    nav.setNextStep();
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Are you registering as an individual IT Technician or for your
        organization?
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="registrationType"
          name="type"
          value={state.registrationType}
          onChange={(event: any) => {
            state.setRegistrationType(event.target.value);
          }}
        >
          <FormControlLabel
            value={RegistrationType.Technician}
            control={<Radio />}
            label="Individual Technician"
          />
          <FormControlLabel
            value={RegistrationType.Organization}
            control={<Radio />}
            label="Organization"
          />
        </RadioGroup>
      </FormControl>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          onClick={handleNext}
          className={classes.button}
          style={{
            backgroundColor: state.registrationType == "" ? "lightgrey" : "#FE5723",
            color: "#FFF",
          }}
          disabled={state.registrationType == ""}
        >
          {"Next"}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default RegistrationTypeForm;
